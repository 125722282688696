@import "_generated-font-mixins.scss";
.histogram {
  font-size: 12px;
  line-height: 1;

  & li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__icon {
    margin-right: 10px;

    &::after {
      @include icon-star-fill;

      font-size: 10px;
      color: #dcdbdb;
    }
  }

  &__counter {
    margin-right: 2px;
    color: #333;
  }

  &__graph {
    flex: 0;

    width: 100%;
    min-width: 100px;
    height: 8px;
    margin-right: 10px;

    background-color: #e9eced;
    border-radius: 4px;

    &__progress {
      display: block;

      min-width: unset;
      height: 8px;

      background-color: #ffae28;
      border-radius: 4px;
    }

    &__counter {
      color: #333;
    }
  }
}
