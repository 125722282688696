@import "_generated-font-mixins.scss";
.rating {
  display: flex;
  margin: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e4e4e4;

  &__block {
    width: 100%;
    padding: 5px 0;
    border-right: solid 1px #ebebeb;

    &__text {
      margin-bottom: 5px;

      font-size: 30px;
      font-weight: 700;
      line-height: 34px;
      color: #333;
    }

    &__icons {
      margin-bottom: 10px;

      &__star {
        &::after {
          @include icon-star-fill;

          margin-right: 3px;
          font-size: 20px;
          color: #dcdbdb;
        }
      }

      &__star__fill {
        &::after {
          @include icon-star-fill;

          margin-right: 3px;
          font-size: 20px;
          color: #ffaf27;
        }
      }
    }

    &__counter {
      font-size: 13px;
      line-height: 15px;
      color: #333;
    }
  }
}

.histogram__wrapper {
  width: 100%;
  padding-left: 20px;
  font-size: 12px;
  line-height: 1;
}
