@import "_generated-font-mixins.scss";
.review__write__container {
  margin-top: 50px;
}

.review__write__container__mobile {
  padding: 20px;
}

.review__mobile {
  display: flex;
  flex-direction: column-reverse;
}

.hide__write__block {
  pointer-events: none;
  opacity: 0.5;
}

.review__list {
  margin: 0 20px 20px;
}

.hidden__text__desktop {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 25px;
  padding: 0;

  font-size: 15px;
  line-height: 17px;
  color: #999;
}

.hidden__text__mobile {
  padding-top: 30px;
  padding-bottom: 0;

  font-size: 18px;
  color: #999;
  text-align: center;
}

.review__rating {
  margin-bottom: 15px;

  &__star {
    &::after {
      @include icon-star-fill;

      margin-right: 3px;
      font-size: 13px;
      color: #dcdbdb;
    }
  }

  &__star__fill {
    &::after {
      @include icon-star-fill;

      margin-right: 3px;
      font-size: 13px;
      color: #ffaf27;
    }
  }
}

.authorized {
  box-sizing: border-box;
  margin-top: 15px;
  margin-bottom: 25px;
  padding: 10px 15px;

  font-size: 13px;
  line-height: 19px;
  color: #ac4a48;

  background-color: #f2dede;
  border-radius: 4px;

  &__mobile {
    margin: 20px;
  }
}

.reviews {
  &__text {
    padding-top: 50px;
    font-size: 18px;
    color: #999;
    text-align: center;
  }
}

.pagination__wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: -25px;
}

.title {
  margin-right: 7px;
  font-size: 17px;
  font-weight: 700;
}

.loading {
  &__text {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;

    padding: 80px;

    color: #999;

    &__mobile {
      padding: 60px;
      color: #999;
    }
  }
}

.smooth {
  opacity: 0.75;
}
