@import "_generated-font-mixins.scss";
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: -40px;
  margin-bottom: 25px;
  padding-top: 40px;

  &__nav {
    display: flex;
    align-items: baseline;

    &__title {
      margin-right: 7px;
      font-size: 17px;
      font-weight: 700;
    }

    &__count {
      font-size: 14px;
      color: #9a9da1;
    }

    &__link {
      margin-left: 20px;
      font-size: 17px;
      line-height: 20px;
      color: #005cc3;

      &:hover {
        color: #c01;
      }
    }
  }

  &__sort {
    &__item {
      position: relative;

      display: inline-block;

      margin-right: 10px;
      padding-right: 15px;

      font-size: 12px;

      &:last-child {
        margin-right: 0;
        padding-right: 0;

        &::after {
          display: none;
        }
      }

      &::after {
        content: '';

        position: absolute;
        top: 13px;
        right: 0;

        display: block;

        width: 1px;
        height: 14px;

        background: #333;
      }
    }
  }
}

.active {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;

  font-size: 15px;
  color: #333;
}
