@import "_generated-font-mixins.scss";
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3px;
}

.review {
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: solid 1px #ebebeb;

  &__user {
    display: flex;
    align-items: center;

    &__link {
      color: #005cc3;

      &:hover {
        color: #c01;
      }
    }

    &__owner {
      margin-left: 5px;
      padding: 4px;

      font-size: 9px;
      line-height: 10px;
      color: #fff;

      background-color: #acb44e;
      border-radius: 4px;
    }
  }

  &:last-child {
    @media screen and (width <= 640px) {
      margin-bottom: 100px;
    }

    margin-bottom: 50px;
    padding-bottom: 0;
    border-bottom: unset;
  }

  &__rating {
    margin-bottom: 15px;

    &__star {
      &::after {
        @include icon-star-fill;

        margin-right: 3px;
        font-size: 13px;
        color: #dcdbdb;
      }
    }

    &__star__fill {
      &::after {
        @include icon-star-fill;

        margin-right: 3px;
        font-size: 13px;
        color: #ffaf27;
      }
    }
  }

  &:hover {
    & .actions__tooltip {
      display: flex;
    }
  }

  &__text {
    margin-bottom: 10px;

    font-size: 15px;
    line-height: 19px;
    word-break: break-word;
    white-space: pre-wrap;
  }

  &__date {
    font-size: 13px;
    line-height: 15px;
    color: #999;
  }
}

.actions {
  position: relative;
  display: flex;

  &__tooltip {
    position: absolute;
    top: -2px;
    right: calc(100% + 15px);

    display: none;
    gap: 30px;
    justify-content: flex-end;

    min-width: 70px;

    font-size: 15px;
    line-height: 17px;
    color: #999;
  }

  &__tooltip__right {
    position: absolute;
    top: -2px;
    right: 0;

    display: flex;
    gap: 30px;
    justify-content: flex-end;

    min-width: 70px;
  }

  &__tooltip__left {
    margin-right: 15px;
    font-size: 15px;
    line-height: 17px;
    color: #c1c1c1;
  }

  &__icon {
    &__edit {
      &::after {
        @include icon-pencil-edit;

        font-size: 15px;
        color: #999;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    &__delete {
      &::after {
        @include icon-trash;

        font-size: 17px;
        color: #999;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.response {
  box-sizing: border-box;
  width: 100%;
  margin-top: 20px;
  padding: 15px;

  background-color: #fafafa;
  border-radius: 4px;

  &__title {
    margin-bottom: 10px;

    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    color: #333;
  }

  &__text {
    margin-bottom: 10px;

    font-size: 15px;
    line-height: 17px;
    color: #333;
    word-break: break-word;
    white-space: pre-wrap;
  }

  &__date {
    font-size: 13px;
    line-height: 15px;
    color: #999;
  }

  &__actions {
    display: flex;
    gap: 10px;
  }
}

.hide__write__block {
  pointer-events: none;
  opacity: 0.5;
}
